import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {setAuthTokenAction} from '../../actions/auth';
import exchangeToken from '../../endpoints/exchangeToken';
import strings from '../../strings';
import Login from '../../components/Login';
import literateLogo from '../../assets/images/logo-white-2.png';
import {SOCKET_ENDPOINT, CHILD_TEST_URL} from '../../constants';

import queryString from 'query-string';
import {Howl} from 'howler';
import nosound from '../../assets/nosound.mp3';
import './styles.scss';

const parsedSearch = queryString.parse(window.location.search);
const verifyAuth = async (key, token) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	headers.append('Authorization', 'Bearer ' + token);

	const response = await fetch(SOCKET_ENDPOINT + '/token/verify?key=' + key, {
		method: 'get',
		headers,
	});

	if (response.ok) {
		return response.json();
	}
	return await Promise.reject(response);
};
const excludeForLang = ['de', 'se'];
class SelectRole extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentView: excludeForLang.includes(strings.getLanguage())
				? 'initial'
				: 'candidate',
			testKey: '',
			roomKey: '',
			adminEmail: '',
			testKeyType: '',
			hasEnteredCode: false,
			ageGroup: null,
			failStartInstruction: this.props.failStart,
		};
	}

	componentWillMount = () => {
		let search = parsedSearch;
		let params = new URLSearchParams(search);
		let key = params.get('key');
		let role = params.get('role');
		let testKeyType = params.get('type');
		let token = params.get('token');
		let ageGroup = params.get('group');

		let authToken = localStorage.getItem('authtoken-child');
		if (authToken) window.localStorage.removeItem('authtoken-child');
		if (role === 'admin') {
			this.setState({
				currentView: 'testadmin',
				testKey: key,
				testKeyType: testKeyType,
				ageGroup: ageGroup,
				token: token,
				hasEnteredCode: false,
			});
			if (key && token) {
				verifyAuth(key, token).then((res) => {
					console.log('authorized');
					this.props.setAuthToken(token);
					this.onAdminSubmit();
				});
			}
		} else if (role === 'candidate') {
			this.setState({
				currentView: 'candidate',
				roomKey: key,
				testKeyType: testKeyType,
			});
		}
	};

	onAdminSubmit = (e) => {
		if (e && e.preventDefault) e.preventDefault();

		const {onSelectRole} = this.props;
		const {testKey, adminEmail, testKeyType, ageGroup} = this.state;

		onSelectRole('testadmin', testKey, testKeyType, adminEmail, ageGroup);

		return false;
	};

	onCandidateSubmit = (e) => {
		e.preventDefault();
		const {onSelectRole} = this.props;
		const {roomKey, testKeyType, ageGroup} = this.state;
		global.myAudio = new Howl({
			src: [nosound],
			html5: true,
			loop: true,
		}).play();
		onSelectRole('testuser', roomKey, testKeyType, ageGroup);
		return false;
	};

	render() {
		const {currentView, hasEnteredCode, testKey, testKeyType} = this.state;

		return (
			<div className="SelectRole">
				<div className="lit-logo-wrapper" style={{marginTop: '15px'}}>
					<img
						src={literateLogo}
						alt="Literate"
						className="title__logo"
					/>
				</div>
				<div className="SelectRoleContainer">
					{currentView === 'initial' && (
						<Fragment>
							<h1 className="title">{strings.childTest}</h1>
							<h2 className="SelectRole__heading">
								{strings.chooseRole}
							</h2>
							<p>{strings.chooseRoleHelp}</p>
							<br />
							<div>
								<button
									className="SelectRole__select-button"
									onClick={() =>
										this.setState({
											currentView: 'testadmin',
										})
									}
								>
									{strings.testadministrator}
								</button>
								<button
									className="SelectRole__select-button"
									onClick={() =>
										this.setState({
											currentView: 'candidate',
										})
									}
								>
									{strings.testcandidate}
								</button>
							</div>
						</Fragment>
					)}
					{currentView === 'testadmin' && (
						<Fragment>
							{!hasEnteredCode && (
								<Fragment>
									<h2>{strings.testadministrator}</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											exchangeToken(testKey).then(
												(results) => {
													window.open(
														`${CHILD_TEST_URL}?key=${testKey}&role=admin&token=${results.token}&type=${results.testType}&group=${results.ageGroup}`,
														'_self'
													);

													if (
														this.props.onStartTest
													) {
														this.props.onStartTest();
													}
												}
											);

											return false;
										}}
									>
										<div className="SelectRole__label">
											<label htmlFor="testkey">
												{strings.testkey}
											</label>
											<input
												className="SelectRole__input"
												name="testkey"
												type="text"
												style={{textTransform:'uppercase'}}
												defaultValue={testKey}
												required
												placeholder="XXXXXXXX"
												onChange={(e) =>
													this.setState({
														testKey: e.target.value
															.trim()
															.toUpperCase(),
													})
												}
											/>
										</div>

										<button
											className="SelectRole__submit-button"
											type="submit"
										>
											{strings.starttest}
										</button>
									</form>
								</Fragment>
							)}
							{hasEnteredCode && (
								<Login
									onLogin={this.onAdminSubmit}
									testKey={this.state.testKey}
								/>
							)}
						</Fragment>
					)}
					{currentView === 'candidate' && (
						<Fragment>
							<h2>{strings.testcandidate}</h2>
							<p>{strings.candidateInstructions}</p>
							<form onSubmit={this.onCandidateSubmit}>
								<input
									style={{textTransform: 'uppercase'}}
									type="text"
									onChange={(e) =>
										this.setState({
											roomKey: e.target.value
												.toUpperCase()
												.trim(),
										})
									}
								/>
								<button
									type="submit"
									style={{marginLeft: '10px'}}
								>
									{strings.gototest}
								</button>
							</form>

							{this.state.failStartInstruction && (
								<span className="candidate-start-fail">
									<p
										className="candidate-start-fail__instructions"
										style={{color: 'yellow'}}
									>
										<span style={{color: 'Coral'}}>
											<b>{strings.warning1} </b>
										</span>
										<em>{strings.warning1text}</em>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://literate.no/personvernerklaering/"
										>
											<u style={{color: 'lime'}}>
												{strings.myTests}
											</u>
										</a>
									</p>
								</span>
							)}
						</Fragment>
					)}
				</div>

				<div>
					<iframe
						src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
						style={{display: 'none'}}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit, isAuthenticated} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
		isAuthenticated,
	};
};

const mapDispatchToProps = {
	setAuthToken: setAuthTokenAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRole);
