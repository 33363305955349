export const se = {
	childTest: `Välkommen till testet`,
	welcomeToTest: `Välkommen till testet`,
	literateChildTest: `Dysmate test`,
	youAreReady: `Du är redo`,
	ready: `redo`,
	chooseRole: `Välj roll`,
	chooseRoleHelp: `Välj vilken roll den här enheten ska ha`,
	chooseLanguage: `Välj Språk`,
	lang_no: `Norska (Bokmål)`,
	lang_nn: `Norska (Nynorsk)`,
	lang_se: `Svenska`,
	lang_de: `Tyska`,
	lang_nl: `Nederländska`,
	lang_en: `Engelska`,
	blue: `blå`,
	green: `grön`,
	pink: `rosa`,
	yellow: `gul`,
	red: `röd`,
	violet: `lila`,
	orange: `orange`,
	gray: `grå`,
	grey: `grå`,
	brown: `brun`,
	zebra: `zebra`,
	woman: `kvinna`,
	bike: `cykel`,
	table: `bord`,
	flower: `blomma`,
	apple: `äpple`,
	unknown: `okänd`,
	testadministrator: `Testadministratör`,
	testcandidate: `Testkandidat`,
	testkey: `Testnyckel`,
	emailaddress: `E-post adresse`,
	starttest: `Starta test`,
	candidateInstructions: `Skriv in kodnyckeln som visas på testadministratörens skärm`,
	gototest: `Gå till testet`,
	jumptotest: `Hoppa till test`,
	roomkey: `Testnyckel: {0}`,
	waitforadminstart: `Vänta till testadministratören startar testet`,
	done: `Ferdig`,
	name: `Navn`,
	sound: `Ljud`,
	helpBox1: `För att starta testet, fyll i all information nedan och anslut sedan ytterligare en enhet genom att följa instruktionerna nedan.`,
	helpBoxList1: `Öppna den här sidan på en annan enhet.`,
	helpBoxList2: `Välj testkandidat`,
	helpBoxList3: `Ange testnyckel ({0})`,
	helpBoxList4: `Klicka "Gå till testet"`,
	helpBoxList5: `När du är redo, tryck på "Starta test" längst ner på denna sida`,
	infoAboutCandidate: `Information om testkandidaten:`,
	generalInformation: `Allmän information`,
	notaBene: `OBS!`,
	candidateReminder: `Kom ihåg att det är kandidatens uppgifter som ska fyllas i, inte dina`,
	firstName: `Förnamn`,
	lastName: `Efternamn`,
	birthDate: `Födelsedatum`,
	gender: `Kön`,
	pickOne: `Välj en`,
	boy: `Man`,
	girl: `Kvinna`,
	unspecified: `Annan`,
	education: `Utbildning`,
	municipality: `Kommun`,
	county: `Län`,
	school: `Skola`,
	educationLevel: `Utbildningsnivå`,
	kindergarten: `Förskola`,
	primarySchool: `Grundskola`,
	juniorHighSchool: `Högstadium`,
	highSchool: `Gymnasium`,
	university: `Universitet`,
	other: `Annat`,
	pickYear: `Välj år`,
	pickClass: `Välj klass`,
	pickLevel: `Välj nivå`,
	pick: `Välj`,
	lastYearBeforeSchool: `sista året i förskola, förskoleklass`,
	class: `Årskurs`,
	classNum: `åk {0}`,
	consentText: `Som testadministratör bekräftar du att du har erforderligt samtycke från testkandidaten för att samla in personuppgifter och att dessa används enligt beskrivning`,
	literatePrivacyPolicy: `Literates integritetspolicy`,
	practiceTasks: `Övningsuppgifter`,
	startPracticeTasks: `Starta övningsuppgifter`,
	listenToInstructions: `Lyssna på instruktionerna.`,
	waitingForCandidate: `Väntar på testkandidat`,
	logIn: `Logga in`,
	loginWithBankId: `Logga in med BankID genom att trycka på någon av knapparna nedan`,
	thisIsForTestAdmins: `Detta är för testadministratörer (dvs certifierade användare med en prenumeration)`,
	bankId: `BankID`,
	bankIdMobile: `BankID på Mobilen`,
	pauseBeforeNextTest: `Testet {0} är nu klart`,
	max2Listens: `Max två uppspelningar per ljudspår`,
	timeUsed: `Tid som förflutit: {0}`,
	start: `Start`,
	pause: `Paus`,
	stop: `Sluta`,
	reset: `Återställa`,
	testIsFinished: `Hela testet är nu klart`,
	TesFinished_Instruction_ONCANDIATESIDE: `Hela testet är nu klart`,
	testFinishedInstruction: `Svaren har sparats och du kan nu stänga denna sida eller starta ett nytt test.`,
	closeTest: `Stäng testet`,
	hotkeyDescription: `Markera korrekt eller felaktigt genom att klicka på knapparna nedan eller använda kortkommandon:`,
	hotkeyCorrect: `A: Korrekt`,
	hotkeyWrong: `L: Fel`,
	hotkeyButtonCorrect: `Korrekt (A)`,
	hotkeyButtonWrong: `Felaktigt (L)`,
	hotkeyButtonUnanswered: `Inget svar gavs (K)`,
	taskNum: `Uppgift {0}`,
	taskNumOf: `Uppgift {0} av {1}`,
	correctAnswer: `Korrekt svar: {0}`,
	nextTest: `Nästa test`,
	correctAnswerGiven: `Rätt svar angivet`,
	expectedAnswer: `Förväntat svar: {0}`,
	clickStartWhenReady: `Klicka på "starta test" när du och testkandidaten är redo.`,
	workingMemoryClickStart: ``,
	testDone: `Testet {0} är nu klart. Klicka på "Nästa test" när du och testkandidaten är redo att fortsätta.`,
	testDoneAndFinished: `Testet {0} är nu klart. Klicka på "Avsluta test".`,
	endTest: `Avsluta test`,
	continue: `Gå vidare`,
	testName_WorkingMemory: `Arbetsminne`,
	testName_WorkingMemoryNumbers: `Arbetsminne: Nummer`,
	testName_WorkingMemoryWords: `Arbetsminne: Ord`,
	testName_PhonemeDeletion: 'Fonemsubtraktionstest',
	testName_RapidNaming: `Snabb automatisk benämning (RAN)`,
	testName_OneMinute: `1-minuts ordavkodning`,
	testName_Spoonerism: `Spoonerismer`,
	testName_TestComplete: `Testet är klart`,
	iConsent: 'Jag samtycker',
	unilingual: 'Enspråkigt',
	bilingual: 'Tvåspråkigt',
	multilingual: 'Flerspråkigt',
	bilinguagualHomeQuestion:
		'Har du vuxit upp i ett en- eller tvåspråkigt hem?',
	om_testname: `1-minuts ordavkodningstest`,
	om_admin1: `Testkandidaten kommer att se rader med ord på sin skärm. Uppgiften är att läsa orden högt så snabbt och korrekt som möjligt.`,
	om_admin2: `Du ska kontrollera vilka ord som läses upp korrekt av testkandidaten och markera svar som korrekta eller felaktiga.`,
	om_intro: `Du kommer nu att få se en lista med ord. När jag säger till har du 1 minut på dig att läsa orden högt, så snabbt och korrekt som du kan. `,
	rn_testname: `Snabb automatiserad benämning`,
	rn_admin1: `Detta test avser att mäta hur snabbt och korrekt testkandidaten kan benämna en serie bilder som visas på skärmen`,
	rn_admin2: `I det här testet får testkandidaten se bilder och uppgiften är att benämna vad som syns på bilden så snabbt och korrekt som möjligt.`,
	rn_admin3: `I den första övningen ska testkandidaten namnge färgerna som visas på dennes skärm. Din uppgift är att hålla reda på vilka färger som läses upp.`,
	rn_adjustIfNotCorrect: `Om svaren som ges inte är korrekta måste du rätta testkandidaten nu. Om alla svar är korrekta kan du fortsätta.`,
	rn_colors: `röd - blå - grön - grå - orange`,
	rn_objects: `zebra - kvinna - bord - blomma - äpple`,
	rn_objectsWithColors: `blå blomma - orange äpple - grön zebra - rött bord - grå kvinna`,
	rn_correctColors: `Rätta färger ges`,
	rn_correctItems: `Rätta ord ges`,
	rn_admin4: `I denna övning ska testkandidaten namnge föremålen som visas på skärmen. Din uppgift är att se till att testkandidaten säger rätt föremål i rätt ordning.`,
	rn_admin5: `Nu ska vi kombinera de två uppgifterna, och testkandidaten får i uppgift att namnge både färgen och föremålet på bilderna som visas.`,
	rn_admin6: `Var uppmärksam på vilka föremål och färger som namnges av kandidaten.`,
	rn_admin7: `Det var alla övningsuppgifter.`,
	rn_admin8: `Testkandidaten kommer att se en serie bilder på sin skärm som hen ska namnge så snabbt och korrekt hen kan.`,
	rn_admin9: `Du ska övervaka de färger och föremål som testkandidaten läser upp och markera om svaren överensstämmer med det som visas på din skärm.`,
	rn_timeUsed: `Tid som förflutit: {0} sekund`,
	rn_intro1: `Du kommer nu att få se några bilder. Din uppgift är att säga vad du ser på bilderna så snabbt och korrekt som du kan. Först ska vi se på färger, och därefter ska vi se på föremål. Låt oss först öva tillsammans.`,
	rn_intro2: `Lyssna noga på namnen på färgerna. röd – blå – grön – grå – orange. Nu är det din tur att säga färgerna så snabbt som du kan.`,
	rn_intro3: `Lyssna noga när jag säger namnen på föremålen zebra – kvinna – cykel – blomma – äpple. Nu är det din tur att säga vilka föremålen är så snabbt som du kan.`,
	rn_intro4: `Nu kommer du att få se en bild med både färger och föremål. Lyssna noga. Nu är det din tur att säga färgerna och föremålen så snabbt som du kan.`,
	rn_intro5: `På nästa bild kommer du att få se färgerna och föremålen flera gånger. Din uppgift är att säga vad du ser, från vänster till höger, och du ska göra det så snabbt och korrekt som du kan, utan att hoppa över något. Tryck på den blå knappen när du är redo att börja.`,
	sp_testname: `Spoonerismer `,
	sp_admin_listen: `Lyssna på testpersonen och hör om de säger rätt ord som det som står på skärmen.`,
	sp_admin1: `I det här testet kommer testkandidaten att höra ordpar. Uppgiften är att växla det första ljudet i de två orden så att två nya ord eller nonsensord skapas. Till exempel blir orden {0}.`,
	sp_admin1part2: ``,
	click_start: `Vi är nu färdiga med övningsuppgifterna. Påminn testkandidaten att vara uppmärksam på att det kommer byggs på med fler och fler siffror efter hand som kandidaten klarar uppgiften. Be eleven invänta signalen/"blipp" innan den svarar så att hen får med sig alla siffror.`,
	click_start_words: `Vi är nu färdiga med övningsuppgifterna. Påminn testkandidaten att vara uppmärksam på att det kommer byggas på med fler och fler ord efter hand som kandidaten klarar uppgiften. Be eleven invänta signalen/"blipp" innan den svarar så att hen får med sig alla orden.`,
	click_start2: ' När testkandidaten klickar på startknappen börjar testet.',
	sp_example1: `dela och gardin ➜ gela och dardin`,
	sp_1correct: `1 Korrekt (L)`,
	sp_2correct: `2 Korrekt (A)`,
	sp_bothWrong: `Båda felaktiga (K)`,
	sp_admin2: `Testkandidaten kommer att säga ordpar där hen växlat de inledande språkljuden. Din uppgift är att lyssna om orden som testkandidaten säger stämmer överens med det som visas på din skärm.`,
	sp_admin3: `Lyssna på testkandidaten och kontrollera om orden hen säger stämmer överens med det som presenteras på din skärm.`,
	sp_instruction: `Lyssna på uppgiften och växla sedan det första ljudet du hör i orden.`,
	sp_intro1: `I det här testet kommer du att höra ordpar, och du ska byta det första ljudet i de två orden så att det blir två nya ord eller nonsensord. Till exempel blir orden {0}. Kom ihåg att det är det {2} du ska ändra – {1}. Så orden sju och bad blir alltså bu och sjad.`,
	sp_intro_reminder: `inte den första bokstaven`,
	sp_intro_reminder2: `första ljudet`,
	sp_intro3: `Vi har nu gjort klart övningsuppgifterna. När du nu klickar på startknappen startar testet. Försök att göra uppgifterna så snabbt du kan. Jag kommer att säga två ord, och du ska byta plats på det första ljudet och säga orden som blir högt till mig.`,
	ws_testname: `Arbetsminne`,
	ws_testname_admin: `Arbetsminne`,
	wsn_testname: `Arbetsminne (siffror)`,
	wsw_testname: `Arbetsminne (ord)`,
	ws_admin1: `Detta test är avsett att mäta arbetsminneskapacitet.`,
	wsn_admin2: `Testkandidaten kommer att höra siffror som hen sedan ska upprepa i omvänd ordning. Du kontrollerar om ordningen stämmer överens med det du ser på din skärm.`,
	wsw_admin2: `Testkandidaten kommer att höra några ord som hen ska upprepa i omvänd ordning. Du kontrollerar om ordföljden stämmer överens med det du ser på din skärm.`,
	wsw_admin2part2: ``,
	wsn_admin2part2: ``,
	wsn_admin3: `Testkandidaten kommer att höra siffror som hen sedan ska upprepa i omvänd ordning. Du kontrollerar om ordningen stämmer överens med det du ser på din skärm.`,
	wsw_admin3: `Lyssna på testkandidaten och kontrollera om hen säger den rätta ordföljden som visas på din skärm.`,
	wsn_intro1: `Jag kommer att säga några siffror. Lyssna noga, för när jag är klar vill jag att du upprepar siffrorna i omvänd ordning. `,
	wsn_intro2: `Till exempel, om jag säger 1 - 3, ska du säga 3 – 1.`,
	wsn_intro3: `Låt oss först öva tillsammans.`,
	wsw_intro1: `Jag kommer att säga några ord. Lyssna noga, för när jag är klar vill jag att du upprepar orden i omvänd ordning. Låt oss först öva tillsammans.`,
	wsw_intro2: `Till exempel, om jag säger ”bil - boll” ska du säga ”boll - bil”.`,
	wsn_test1: `Lyssna på siffrorna och upprepa dem i omvänd ordning.`,
	wsw_test1: `Lyssna på orden och upprepa dem i omvänd ordning.`,
	warning1: `Varning 1 - `,
	warning1text: `Det verkar som att testet med denna testnyckel inte har startat ordentligt ännu. Testadministratören måste starta rätt test från`,
	myTests: `Mina tester`,
	warning2: `Varning 2`,
	warning2text: `- Det verkar som att du har inaktiverat spårning över flera webbplatser i din webbläsare. För att använda våra tjänster måste du aktivera den. Tryck`,
	linkForInstructions: `här`,
	remainingTime: `Återstående tid`,
	trialIsNowOver: `Övningsuppgifterna är nu klara`,
	//PhonemeDeletion
	pd_testname: 'Fonemsubtraktionstest',
	pd_admin1:
		'Testkandidaten får instruktioner upplästa genom övningsuppgifterna. Du måste se till att rätt svar ges. Om svaret är felaktigt måste du rätta kandidaten.',
	pd_admin2:
		'Lyssna på testkandidaten och notera om hen säger rätt svar. Kandiaten kommer att få uppgifter som "Kan du säga ris utan /r/". Nedan visas både det ursprungliga ordet och det förväntade svaret.',
	pd_intro1:
		'I detta test kommer jag att be dig säga några ord och därefter fråga dig vilket ord det blir när vi tar bort ett visst ljud. Till exempel, om vi tar bort /s/ från stall får vi ordet tall.',
	pd_intro2:
		'Innan testet börjar, gör vi med några övningsexempel tillsammans.',
	pd_introDone:
		'Jättebra! Du klarade övningsuppgifterna. I testet ska du nu göra precis samma sak, det vill säga säga högt  vilket ord det blir när ljuden byts ut.  Du har 30 sekunder på dig att svara på en uppgift, sedan går vi vidare till nästa. Tryck på den blåa knappen när du känner dig redo att starta testet.',
	pd_testInstruction:
		'Lyssna på uppgiften och säg vilket ord det blir när ljudet tas bort.',
};

export default se;
