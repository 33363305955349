import React from 'react';

import {getRapidNaming, getTestLanguage} from '../../constants/tests.js';

import StopWatch from '../../components/StopWatch';

import AdminTestPanel from '../../components/AdminTestPanel';
import AdminReadyButton from '../../components/AdminReadyButton';

import strings from '../../strings';

class Admin extends AdminTestPanel {
	getTestName = () => {
		return strings.rn_testname;
	};

	getImage = (color, item) => {
		const images = getTestLanguage().rnImages;
		if (images[color][item]) return images[color][item];
		console.error('Item ' + color + item + ' not found');
		return null;
	};

	updateTimeUsed = (timeUsed) => {
		this.setState({timeUsed: timeUsed});
	};

	renderIntro = () => {
		const {currentStep} = this.props;

		return (
			<div>
				<p>{strings.rn_admin1}</p>
				{currentStep === 0 && (
					<>
						<p>{strings.rn_admin2}</p>
						<button
							onClick={() => {
								this.props.nextStep();
							}}
						>
							{strings.startPracticeTasks}
						</button>
					</>
				)}
				{currentStep === 1 && (
					<>
						<p>
							{strings.rn_admin3}
							<br />
							<br></br>
							
							{strings.formatString(
								strings.expectedAnswer,
								<b style={{color:'orange'}}>{strings.rn_colors}</b>
							)}
						</p>
						<p>{strings.rn_adjustIfNotCorrect}</p>
						<button
							onClick={() => {
								this.props.nextStep();
							}}
						>
							{strings.rn_correctColors}
						</button>
					</>
				)}
				{currentStep === 2 && (
					<>
						<p>
							{strings.rn_admin4}
							<br />
						</p>
						<p>
						
							{strings.formatString(
								strings.expectedAnswer,
								<b style={{color:'orange'}}>{strings.rn_objects}</b>
							)}
						</p>
						<p>{strings.rn_adjustIfNotCorrect}</p>
						<button onClick={() => this.props.nextStep()}>
							{strings.rn_correctItems}
						</button>
					</>
				)}
				{currentStep === 3 && (
					<>
						<p>{strings.rn_admin5}</p>
						<p>{strings.rn_admin6}</p>
						<p>
						
							{strings.formatString(
								strings.expectedAnswer,
								<b style={{color:'orange'}}>{strings.rn_objectsWithColors}</b>
							)}
						</p>
						<button onClick={this.props.nextStep}>
							{strings.rn_correctItems}
						</button>
					</>
				)}
				{currentStep === 4 && (
					<>
						<p>{strings.rn_admin7}</p>
						<p>
							{strings.getLanguage() === 'de'
								? strings.clickStartWhenReadyEmpty
								: strings.clickStartWhenReady}
						</p>
						<AdminReadyButton
							remoteIsReady={this.props.candidateReady}
							onClick={this.props.nextStage}
						/>
					</>
				)}
			</div>
		);
	};
	returnProperAdjective = (color, item) => {
		console.log('THis is the color', color);
		console.log('And this is the item', item)
		let returnAnswer = `${color} ${item}`
		const itemWithColor = `${color}_${item}`;
		switch (itemWithColor) {
			case 'grön_bord':
				returnAnswer = 'grönt bord';
				break;
			case 'grön_äpple':
				returnAnswer = 'grönt äpple';
				break;
			case 'grå_bord':
				returnAnswer = 'grått bord';
				break;
			case 'grå_äpple':
				returnAnswer = 'grått äpple';
				break;
			default:
				returnAnswer = `${color} ${item}`;
		}
	return returnAnswer;
	
	}

	returnCorrectAnswer(color,item){

		const adjective = {
			blå:'blått',
			röd:'rött',
			grön:'grönt',
			grå:'grått',
		}

		if(adjective.hasOwnProperty(color) && ['äpple','bord'].includes(item)){
		
			return `${adjective[color]} ${item}`;
		}
	
		return `${color}  ${item}`;
	}

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getRapidNaming(this.props.ageGroup).getSteps();
		const testLang = getTestLanguage();

		return (
			<div>
				<p>
					<StopWatch autoStart integer onTick={this.updateTimeUsed} />
					<br />
					{strings.rn_admin8}
					<br />
					{strings.rn_admin9}
					<br />
					{strings.hotkeyCorrect} <br /> {strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						steps.length
					)}
				</p>
				<p>
				
					{strings.formatString(
						strings.expectedAnswer,
						<b style={{color:'orange'}}>
							{this.returnCorrectAnswer(
								strings[
									testLang.rnColors[steps[currentStep].color]
								],

							strings[testLang.rnItems[steps[currentStep].item]])
	}
						
						</b>
					)}
				</p>

				<p className={`adminItem`}>
					<img
						alt=""
						src={this.getImage(
							steps[currentStep].color,
							steps[currentStep].item
						)}
					/>
				</p>
				<button
					onClick={(e) => {
						e.stopPropagation();

						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();

						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.rn_testname
					)}
				</p>
				<p>
					{strings.formatString(
						strings.rn_timeUsed,
						this.state.timeUsed
					)}
				</p>
				<button onClick={() => this.props.nextStage()}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
	answerCB = () => {
		this.props.startTimer();
	};
}

export default Admin;
